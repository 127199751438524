import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  clients = [1,2,3,4,5];
  contents = [1, 2, 3,4, 5].map((data) => {
    return {
      tab: `Service ${data}`,
      title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit' + data,
      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum non incidunt
      aut mollitia neque laboriosam aspernatur? Voluptatem eveniet soluta ${data}`
    }
  });
  constructor() { }

  ngOnInit() {
  }

  public tabsContant = [
    {
      tab:'Pipe laying',
      title:'Pipe laying and pipe fitting services ',
      description:'we execute pipe laying and HSC connection project including trench cutting, pipe laying ,Pipe joining ,TESTING, housing connection and back filling and restoration works.'
    },
    {
      tab:'Water proofing',
      title:'Water proofing',
      description:'we provide Waterproofing services to process of making an object or structure waterproof or water-resistant so that it remains relatively unaffected by water or resisting the ingress of water under specified conditions. '
    },
    {
      tab:'Painting',
      title:'Painting',
      description:'nutech group has resourceful team of skilled personals who are experts in industrials and civil painting works. Nuteck provide various solutions like painting and coating on the wall, machineries and mechanical structures.'
    },
    {
      tab:'Grouting',
      title:'Grouting & Geniting',
      description:'we provide services for Grouting Repairing of cracks The wide cracks may be repaired by filling them with portland cement grout and The guniting is the most effective process of repairing concrete work which has been damaged due to inferior work or other reasons. It is also used for providing an impervious layer. '
    },
    {
      tab:'Rehabilitation',
      title:'Rehabilitation',
      description:`structural repairs and rehabilitation is a process of reconstruction and renewal of a facility or its structural elements. This involves determining the origin of distress, removing damaged materials and causes of distress, as well as selecting and applying appropriate repair materials that extend a structure's life.`
    }
  ]

    public clientsLog = [
    {
      img:`/assets/client/RIL_Logo.png`
    },
    {
      img:'/assets/client/logo_fabtech.png'
    },
    {
      img:'/assets/client/IOT INFRA.jpg'
    },
    {
      img:'/assets/client/oritech.jpg'
    },
    {
      img:'/assets/client/logo.png'
    },
    {
      img:'/assets/client/MCCPL.jpg'
    },
    // {
    //   img:'/assets/client/logo-lalitpur-bajaj.gif'
    // },
    // {
    //   img:'/assets/client/ISO-9001-CERTIFIED-249x300.png'
    // }
  ]

}
