import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-owl-carousel',
  templateUrl: './owl-carousel.component.html',
  styleUrls: ['./owl-carousel.component.scss']
})
export class OwlCarouselComponent implements OnInit {
  title = 'owlcarouselinAngular';
  public stars = [1, 2, 3, 4];
  public unStars = [1];
  Images = ['../../../assets/user.png', '../../../assets/user.png', '../../../assets/user.png',  '../../../assets/user.png'];  
  
  SlideOptions = { items: 3, nav: true, loop: true,  dots: false, navText: ["<img src='../../../assets/next-arrow-left.svg'>","<img src='../../../assets/next-arrow-right.svg'>"], responsive: {
    0: {
      items: 1 
    },
    400: {
      items: 2
    },
    760: {
      items: 2
    },
    1000: {
      items: 3
    }
  } 
};  
  CarouselOptions = { items: 3, nav: true };  
  constructor() { }

  ngOnInit() {
  }

  

}
