import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.scss']
})
export class ViewProductComponent implements OnInit {
  productImages = [{ id: 1, src: '../../../assets/imageOne.jpg' },
  { id: 2, src: '../../../assets/imageOne.jpg' },
  { id: 3, src: '../../../assets/imageOne.jpg' },
  { id: 4, src: '../../../assets/imageOne.jpg' }]
  selectedImage = this.productImages[0];
  pageData
  constructor(public router: ActivatedRoute,private route: Router) {
    this.router.params.subscribe(params => this.pageData = params);
    console.log(this.pageData);
   }

  ngOnInit() {
  }
  selectProduct(image) {
    this.selectedImage = image;
  }
}
