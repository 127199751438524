import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-saddle',
  templateUrl: './saddle.component.html',
  styleUrls: ['./saddle.component.scss']
})
export class SaddleComponent implements OnInit {
  productImages = [{ id: 1, src: '../../../assets/Product Image/NUPAC_Saddle_Clamp_02.jpg' },
  { id: 2, src: '../../../assets/Product Image/NUPAC_Saddle_Clamp_04.jpg' },
  { id: 3, src: '../../../assets/Product Image/NUPAC_Saddle_Clamp_03.jpg' },
  // { id: 4, src: '../../../assets/imageOne.jpg' }
]
  selectedImage = this.productImages[0];
  pageData
  constructor(public router: ActivatedRoute, private route: Router) {
    this.router.params.subscribe(params => this.pageData = params);
    console.log(this.pageData);
  }

  ngOnInit() {
  }
  selectProduct(image) {
    this.selectedImage = image;
  }

  public prodInfo = [
    {
      title: 'Saddle Clamp',
      description: `
      Nupac provide the solution to connect through any pipe using an easy to assemble saddle clamp. All you need to do is drill a hole on the pipe wherever required and assemble the clamp in line with the hole. The saddle clamps come at various operating pressure based on your requirements. It is also possible to make the clamps as per your fittings and requirements.`,
      models: [
        "Durable water resistant materials for long life ",
        "Easy Fitting system for onsite fixing .",
        "Rubber Padding for water sealing over long service life",
        "Adjustable strap Length – One strap accepts many pipe sizes.",
        "Intensively tested for pressure reversal over long duration of service life. "
      ],
      materials: [
        "HDPE",
        // "Stainless Steel"
      ]
    }
  ]

}
