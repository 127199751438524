import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  public stars = [1, 2, 3, 4];
  public unStars = [1];
  loginForm;
  constructor(private toast: ToastrService,private http: HttpClient) { }

  ngOnInit() {
    this.toast.success('Hello world!', 'Toastr fun!');
  }

  formData = {
    name: "",
    email: "",
    company: "",
    phoneNumber: "",
    message: "",

  }

  onFormSubmit(loginForm) {
    // return console.log(this.formData)
     this.http.post('https://rheodigital.herokuapp.com/company/nuteck/contactUs',this.formData).subscribe(response => {
       this.toast.success('success', '', response['msg'])
       alert("Message Send")
       loginForm.resetForm();
     }, error => {
       this.toast.success('error', "", error['error']['msg'])
       alert("Duplicate Message Send")
       loginForm.resetForm();
     })
     // console.log(this.formData)
   }


}
