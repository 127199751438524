import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-ball',
  templateUrl: './ball.component.html',
  styleUrls: ['./ball.component.scss']
})
export class BallComponent implements OnInit {
  productImages = [{ id: 1, src: '../../../assets/Product Image/Ball Valve.jpg' },
  { id: 2, src: '../../../assets/Product Image/Ball Valve 2.jpg' },
  // { id: 3, src: '../../../assets/imageOne.jpg' },
  // { id: 4, src: '../../../assets/imageOne.jpg' }
]
  selectedImage = this.productImages[0];
  pageData
  constructor(public router: ActivatedRoute,private route: Router) {
    this.router.params.subscribe(params => this.pageData = params);
    console.log(this.pageData);
   }

  ngOnInit() {
  }
  selectProduct(image) {
    this.selectedImage = image;
  }

  public prodInfo = [
    {
      title:'Ball Valve',
      description:'Nupac ball valve offer hassle free connection in plumbing and industrial applications. The use of high-quality lead-free brass allows for a safe water pipeline connection for household usage that is highly durable and has a long working life. Easy installation and reliable service make the valves suited for use in most heating, plumbing and industrial applications.',
      models:[
        "Quarter turn female threaded",
        "Quarter turn male female threaded"
      ],
      materials:[
        "Brass",
        "Stainless Steel"
      ]
    }
  ]

  public tableData = [
      {
        title:'Size (in)',
        data:[
          "½",
          "¾",
          "1",
          "1¼",
          "1½",
          "2"
        ]
      },
      {
        title:'Thread Size(mm)',
        data:[
          "62",
          "71",
          "83",
          "98",
          "110",
          "133"
        ]
      },
      {
        title:'Pressure rating',
        data:[
          "PN40",
          "PN40",
          "PN40",
          "PN40",
          "PN40",
        ]
      },
      {
        title:'Product Code',
        data:[
          "NU20BV0HPN40",
          "NU20BV0QPN40",
          "NU20BV1PN40",
          "NU20BV1QPN40",
          "NU20BV1HPN40",
          "NU20BV2PN40",
        ]
      }
  ]
}
