import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-civil',
  templateUrl: './civil.component.html',
  styleUrls: ['./civil.component.scss']
})
export class CivilComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public carouselContent = [
    {
      img: `/assets/Slider/pipes and fittings.jpg`,
      title:' HSC Connection and pipe fittings',
      description:'We manufactures and markets complete range of house connection pipe fittings including valves, saddle clamps and compression pipe fittings.'
    },
    {
      img: `/assets/Slider/construction-silhouette_1150-8336.jpg`,
      title:'Civil & Constructions Services',
      description:'WE specialize in civil construction services such as Rehabilitation works, Pipeline laying and connection, Structure painting and finishing works.'
    },
    {
      img: `/assets/Slider/Fbrication.jpg`,
      title:'Engineering and Fabrication Services',
      description:'We solve complex engineering problems backed by more then 30 years of experience in fabrication , manufacturing and consulting engineering .'
    }
  ]

}
