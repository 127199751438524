import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-filter-accordian',
  templateUrl: './filter-accordian.component.html',
  styleUrls: ['./filter-accordian.component.scss']
})
export class FilterAccordianComponent implements OnInit {
  public icons = {
    dropDown: faChevronDown
  }
  filters = ['panel 1', 'panel 2', 'panel 3', 'panel 4', 'panel 5']
  @ViewChild('acc', { static: true }) accordian: NgbAccordion;
  constructor() { }

  ngOnInit() {
  }

  toggle(id) {
    this.accordian.toggle(id)
  }

  filterData(i) {
    console.log(i);
  }

  public filtersOptions = [
    {
      title: 'Category',
      options: [
        "Clamps",
        "Valve",
        "Pipe fittings",
        "Pipe saddle",
        "Turn buckle",
        "Other fittings "
      ]
    },
    {
      title: 'Material',
      options: [
        "M.S",
        "Stainless Steel",
        "Brass",
        "Copper",
        "Alloy",
        "Poly Polypropylene(PP)",
        "HDPE",
        "ABS"
      ]
    },
    {
      title: 'Application',
      options: [
        "Piping",
        "Drinking water",
        "Construction materials"
      ]
    },
    {
      title: 'Size',
      options: [
        "L< 100 MM",
        "< 100 TO 500 MM",
        "> 500 MM"
      ]
    },
    {
      title: 'Weight',
      options: [
        "< 100 g",
        "100 to 500 g",
        "500 to 2000 g ",
        "> 2000 g"
      ]
    }
  ]
  
}
