import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-item-card',
  templateUrl: './product-item-card.component.html',
  styleUrls: ['./product-item-card.component.scss']
})
export class ProductItemCardComponent implements OnInit {
  @Input() ProdData:any;
  constructor(private route:Router) { }

  ngOnInit() {
  }

  viewProduct(productName) {
    this.route.navigate(['mechanical','piping-solution',productName])
  }

}
