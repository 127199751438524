import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-piping-solutions',
  templateUrl: './piping-solutions.component.html',
  styleUrls: ['./piping-solutions.component.scss']
})
export class PipingSolutionsComponent implements OnInit {
  [x: string]: any;
  public icons = {
    dropDown: faChevronDown
  }
  // filters = ['Material']
  @ViewChild('acc', { static: true }) accordian: NgbAccordion;
  searchInput;
  temp;
  // isSelected = false;
  // Checked = true;
  Checked: boolean;
  masterSelected: boolean;
  data = [];
  checkedList;
  searchOnlyUserEntity = false;
  DisplayProductList: any[];
  ProductData: any;
  constructor(private route: Router) {
    this.masterSelected = true;
  }

  ngOnInit() {
    this.temp = JSON.parse(JSON.stringify(this.ProdData));
    this.DisplayProductList = JSON.parse(JSON.stringify(this.ProdData));
  }

  viewProduct(productName) {
    console.log(productName);
    this.route.navigate(['/mechanical/' + productName])
  }

  toggle(id) {
    this.accordian.toggle(id)
  }

  // filterData(j) {
  //   // return console.log(e)
  //   let duplicatePushArray = [];
  //   this.data.push(j)
  //   for (let i = 0; i < this.data.length; i++) {
  //     if (duplicatePushArray.indexOf(this.data[i]) === -1) {
  //       duplicatePushArray.push(this.data[i]);
      

  //     }
  //   }
  //   console.log('Final Array: ', duplicatePushArray)
  // }



  searchProd() {

    if (this.searchInput.length < 2) {
      this.DisplayProductList = this.temp;
      return;
    }
    if (this.searchOnlyUserEntity) {
      this.DisplayProductList = this.temp.filter(
        row => {
          if (row.name) {
            return (!this.searchInput ||
              row.name.toLowerCase().includes(this.searchInput.toLowerCase())
            )
          }
        }
      );
    } else {
      this.DisplayProductList = this.temp.filter(
        row => {
          if (row.title) {
            // console.log(this.searchInput)
            return (!this.searchInput ||
              row.title.toLowerCase().includes(this.searchInput.toLowerCase())
            )
          }
        });
    }
  }


  async OnChange(event: any) {
    //Emptying current product list
    
    // console.log(event.target.value);
    // console.log(this.selectedMaterial);
    // console.log(typeof this.filtersOptions,this.filtersOptions[0].options);
    let selectedOption = [];
    let filteredOPtions = this.filtersOptions[0].options;
    // console.log("filteredOPtions",filteredOPtions);
    if(filteredOPtions.length > 0){
      selectedOption = await filteredOPtions.filter((each) => each.Checked === true);
    }
    // console.log("selectedOption",selectedOption);
    if(selectedOption.length > 0){
      let filteredProduct = [];
      var promises = selectedOption.map((each,index) => {
        return Promise.all( this.temp.map((e) => {
          if(e.materials === each.Name){
            // console.log(e,each);
            filteredProduct.push(e)
            return e 
          }
        }))
      })
      Promise.all(promises).then((result) => {
        // console.log(result,filteredProduct)
        this.DisplayProductList = filteredProduct;
      })
    }

    // if (this.selectedMaterial.length > 0){

    //   // this.ProdData = [];
    //   this.DisplayProductList = [];
      
    //   for (var i = 0; i < this.selectedMaterial.length; i++) {
    //     let lst = this.filtersOptions.filter(x => x.options.Name == this.selectedMaterial[i].options.Name);        
    //     for (var j = 0; j < lst.length; j++) {
    //     this.DisplayProductList.push(lst[j]);


    //     }
    //   }

    // }
    
    if (this.selectedMaterial.length == 0) {
      this.DisplayProductList = this.ProdData;
    }
  }

  get selectedMaterial() {
    //Get all the selected brands
    return this.filtersOptions[0].options.filter(opt => opt.Name)
  }

  checkUncheckAll() {
    for (var i = 0; i < this.filtersOptions.length; i++) {
      // this.filtersOptions[i].options.Checked = false ;
      this.filtersOptions[0].options.forEach((element) => {
        element.Checked = false;
      })
      if (this.selectedMaterial.length == 0) {
        this.DisplayProductList = this.ProdData;
      }

      // console.log(this.filtersOptions[i].options.Checked = false)
    }
    // this.OnChange;

  }

  // isAllSelected(e) {
  //   this.masterSelected = this.filtersOptions.every(function (item: any) {
  //     return item.isSelected == true;

  //   })
  //   this.getCheckedItemList();

  // }

  // getCheckedItemList() {
  //   this.checkedList = [];
  //   for (var i = 0; i < this.filtersOptions.
  //     length; i++) {
  //     if (this.filtersOptions[i].options.Checked)
  //       this.checkedList.push(this.filtersOptions[i]);
  //     // this.hideItem = this.subcategories[i].isSelected;
  //   }
  //   // this.checkedList = JSON.stringify(this.checkedList);
  //   if (this.checkedList == '') {
  //     return console.log("not fond")
  //   } else {
  //     let duplicatePushArray = [];
  //     for (let i = 0; i < this.checkedList.length; i++) {
  //       if (duplicatePushArray.indexOf(this.checkedList[i]) === -1) {
  //         duplicatePushArray.push(this.checkedList[i]);
  //         this.checkedList = this.checkedList.filter(item => {
  //           console.log(item.name)
  //         })
  //       }
  //     }
  //   //  return console.log("search fn")
  //   }
  //   // console.log(this.checkedList)
  // }


  // public filtersOptions = [
  //   { name: 'M.S',isSelected: false },
  //   { name: 'Stainless Steel', isSelected: false },
  //   { name: 'Brass', isSelected: false },
  //   { id: 4, name: 'Copper', value: 'copper', isSelected: false },
  //   { id: 5, name: 'Alloy', value: 'alloy', isSelected: false },
  //   { id: 6, name: 'Poly Polypropylene(PP)', value: 'polyPolypropylene', isSelected: false },
  //   { id: 7, name: 'HDPE', value: 'HDPE', isSelected: false },
  //   { id: 9, name: 'ABS', value: 'ABS', isSelected: false }
  // ]

  public filtersOptions = [
    // {
    //   title: 'Category',
    //   options: [
    //     "Clamps",
    //     "Valve",
    //     "Pipe fittings",
    //     "Pipe saddle",
    //     "Turn buckle",
    //     "Other fittings"
    //   ]
    // },
    {
      title: 'Material',
      options: [
        {Name: "M.S", Checked: false },
        {Name: "Stainless Steel", Checked: false },
        {Name: "Brass", Checked: false },
        {Name: "Copper", Checked: false },
        {Name: "Alloy", Checked: false },
        {Name: "Poly Polypropylene(PP)", Checked: false },
        {Name: "HDPE", Checked: false },
        {Name: "ABS", Checked: false }

        // "M.S",
        // "Stainless Steel",
        // "Brass",
        // "Copper",
        // "Alloy",
        // "Poly Polypropylene(PP)",
        // "HDPE",
        // "ABS"
      ]
    }
    // {
    //   title: 'Application',
    //   options: [
    //     "Piping",
    //     "Drinking water",
    //     "Construction materials"
    //   ]
    // },
    // {
    //   title: 'Size',
    //   options: [
    //     "L< 100 MM",
    //     "< 100 TO 500 MM",
    //     "> 500 MM"
    //   ]
    // },
    // {
    //   title: 'Weight',
    //   options: [
    //     "< 100 g",
    //     "100 to 500 g",
    //     "500 to 2000 g ",
    //     "> 2000 g"
    //   ]
    // }
  ]


  public ProdData = [
    {
      id: 'ball',
      img: '/assets/Product Image/Ball Valve.jpg',
      title: 'Ball Valve ',
      materials: 'Brass'
    },
    {
      id: 'compression',
      img: '/assets/Product Image/NUPAC_Compression_Fittings_Coupling1.jpg',
      title: 'Compression Non Reducing Coupling',
      materials: 'HDPE'
    },
    {
      id: 'reducing',
      img: '/assets/Product Image/NUPAC_Compression_Fittings_Reducer1.jpg',
      title: 'Compression Reducing Coupling',
      materials: 'HDPE'
    },
    {
      id: 'elbow-female',
      img: '/assets/Product Image/ElbowFemale3.jpg',
      title: 'Compression Elbow  Female Threaded ',
      materials: 'HDPE'
    },
    {
      id: 'elbow-male',
      img: '/assets/Product Image/NUPAC_Compression_Fittings_ElbowMale1.jpg',
      title: 'Compression Elbow  Male Threaded ',
      materials: 'HDPE'
    },
    {
      id: 'male-threaded',
      img: '/assets/Product Image/NUPAC_Compression_Fittings_MTA1.jpg',
      title: 'Compression Male Threaded  Adapters ',
      materials: 'HDPE'
    },
    {
      id: 'female-threaded',
      img: '/assets/Product Image/NUPAC_Compression_Fittings_FTA1.jpg',
      title: 'Compression Female Threaded  Adapters',
      materials: 'HDPE'
    },
    {
      id: 'saddle',
      img: '/assets/Product Image/NUPAC_Saddle_Clamp_02.jpg',
      title: 'Saddle Clamp',
      materials: 'HDPE'
    }
  ]
}
