import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public caseStudy = [
    {
      image : `/assets/Slider/Providing Clean and safe drinking water (Case -1).png`,
      title:'Providing Clean and safe drinking water to millions ',
      description:'Nupack (Brand of Nuteck group ) Supplies more than Hundred thousand pipe fittings to Nagpur-draught effected region of india.'
    },
    {
      image : `/assets/Slider/Solving complex engineering tunneling Problems.jpg`,
      title:'Solving complex engineering tunneling Problems',
      description:'Nuteck group has partnered  with ITD cementation to solve complex tunneling issues in a very crowded urban region of Gujrat.'
    }
  ]

  public carouselContent = [
    {
      img: `/assets/Slider/pipes and fittings.jpg`,
      title:' Hsc Connection and pipe fittings',
      description:'We manufactures and markets complete range of house connection pipe fittings including valves, saddle clamps and compression pipe fittings.'
    },
    {
      img: `/assets/Slider/construction-silhouette_1150-8336.jpg`,
      title:'Civil & Constructions Services',
      description:'We specialize in civil construction services such as Rehabilitation works, Pipeline laying and connection, Structure painting and finishing works.'
    },
    {
      img: `/assets/Slider/Fbrication.jpg`,
      title:'Engineering and Fabrication Services',
      description:'We solve complex engineering problems backed by more then 30 years of experience in fabrication , manufacturing and consulting engineering .'
    }
  ]

}
