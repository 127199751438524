import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qualification-certificate',
  templateUrl: './qualification-certificate.component.html',
  styleUrls: ['./qualification-certificate.component.scss']
})
export class QualificationCertificateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public clientsLog = [
    {
      img:'/assets/cerificate/NUTECK ENGIMECH ISO 9001 CERT Quality_page-0001.jpg'
    },
    {
      img:'/assets/cerificate/NUTECK ENGIMECH OHSAS 18001 CERT AHMEDABAD_page-0001.jpg'
    }
  ]

}
