import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fabrication',
  templateUrl: './fabrication.component.html',
  styleUrls: ['./fabrication.component.scss']
})
export class FabricationComponent implements OnInit {
  cards = [1,2,3,4,5]

  constructor() { }

  ngOnInit() {
  }

}
