import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-reducing-coupling',
  templateUrl: './reducing-coupling.component.html',
  styleUrls: ['./reducing-coupling.component.scss']
})
export class ReducingCouplingComponent implements OnInit {
  productImages = [{ id: 1, src: '../../../assets/Product Image/NUPAC_Compression_Fittings_Reducer1.jpg' },
  // { id: 2, src: '../../../assets/Product Image/NUPAC_Compression_Fittings_Reducer2.jpg' },
  // { id: 3, src: '../../../assets/imageOne.jpg' },
  // { id: 4, src: '../../../assets/imageOne.jpg' }
]
  selectedImage = this.productImages[0];
  pageData
  constructor(public router: ActivatedRoute,private route: Router) {
    this.router.params.subscribe(params => this.pageData = params);
    console.log(this.pageData);
   }

  ngOnInit() {
  }
  selectProduct(image) {
    this.selectedImage = image;
  }

  public prodInfo = [
    {
      title:'Compression Reducing Coupling',
      description:`Nupac compression fittings offer hassle free connection in any conditions. The use of high quality polymers and stainless steel allows for extended reliability and zero water wastage because of operational leakages. 
      All the compression fittings have either PN16 or PN40 operating pressure ratings and are test at our inhouse facility over long duration of time.`,
      models:[
        "Coupling with cap",
        "Coupling without cap"
      ],
      materials:[
        "HDPE",
        // "Stainless Steel"
      ]
    }
  ]

}
