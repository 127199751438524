import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbSlideEvent, NgbSlideEventSource, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
  @Input() carouselContent:any;

  // images = [1, 2, 3].map((data) => {
  //   return {
  //     id: 'slideOne',
  //     img: `https://picsum.photos/900/500?random&t=${Math.random()}`,
  //     title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.' + data,
  //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias aliquid quaerat qui magnam consequatur voluptatum earum magni molestias blanditiis neque porro, perferendis quam cupiditate consequuntur molestiae facilis eveniet corrupti odio.' + data
  //   }
  // });
  constructor() { }

  ngOnInit() {
  }
  prev() {
    this.carousel.prev();
  }
  next() {
    this.carousel.next();
  }

}
