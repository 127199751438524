import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-case-study-small',
  templateUrl: './case-study-small.component.html',
  styleUrls: ['./case-study-small.component.scss']
})
export class CaseStudySmallComponent implements OnInit {
  @Input() caseStudy:any;
// image = `https://picsum.photos/900/500?random&t=${Math.random()}`;
  constructor() { }

  ngOnInit() {
  }


}
