import { PolicieComponent } from './pages/policie/policie.component';
import { FemaleThreadedComponent } from './pages/piping-solutions/female-threaded/female-threaded.component';
import { MaleThreadedComponent } from './pages/piping-solutions/male-threaded/male-threaded.component';
import { ElbowMaleComponent } from './pages/piping-solutions/elbow-male/elbow-male.component';
import { ElbowFemaleComponent } from './pages/piping-solutions/elbow-female/elbow-female.component';
import { ReducingCouplingComponent } from './pages/piping-solutions/reducing-coupling/reducing-coupling.component';
import { SaddleComponent } from './pages/piping-solutions/saddle/saddle.component';
import { CompressionComponent } from './pages/piping-solutions/compression/compression.component';
import { BallComponent } from './pages/piping-solutions/ball/ball.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { QualificationCertificateComponent } from './pages/qualification-certificate/qualification-certificate.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { HomeComponent } from './pages/home/home.component';
import { CivilComponent } from './pages/civil/civil.component';
import { CaseStudiesComponent } from './pages/case-studies/case-studies.component';
import { PipingSolutionsComponent } from './pages/piping-solutions/piping-solutions.component';
import { FabricationComponent } from './pages/fabrication/fabrication.component';
import { ViewProductComponent } from './pages/view-product/view-product.component';


const routes: Routes = [{
  path: '',
  component: HomeComponent
}, {
  path: 'civil',
  component: CivilComponent
}, {
  path: 'case-studies',
  component: CaseStudiesComponent
}, {
  path: 'mechanical',
  children: [{
    path: 'piping-solution',
    component: PipingSolutionsComponent,
  },
  {
    path: 'piping-solution/:product',
    component: ViewProductComponent
  },
  {
    path:'ball',
    component:BallComponent
  },
  {
    path:'compression',
    component:CompressionComponent
  },
  {
    path:'reducing',
    component:ReducingCouplingComponent
  },
  {
    path:'elbow-female',
    component:ElbowFemaleComponent
  },
  {
    path:'elbow-male',
    component:ElbowMaleComponent
  },
  {
    path:'male-threaded',
    component:MaleThreadedComponent
  },
  {
    path:'female-threaded',
    component:FemaleThreadedComponent
  },
  {
    path:'saddle',
    component:SaddleComponent
  },
  {
    path: 'fabrication',
    component: FabricationComponent
  },
  {
    path: 'fabrication/:product',
    component: ViewProductComponent
  },
  ]
}, {
  path: 'company/about-us',
  component: AboutUsComponent
},{
  path:'policies',
  component:PolicieComponent
},
{
  path:'privacy/policies',
  component:PolicieComponent
},
 {
  path: 'company/qualification-certificate',
  component: QualificationCertificateComponent
},
{
  path: 'company/contact-us',
  component: ContactUsComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
