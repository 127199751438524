import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-cards',
  templateUrl: './service-cards.component.html',
  styleUrls: ['./service-cards.component.scss']
})
export class ServiceCardsComponent implements OnInit {
  cards = [{ title: 'Civil', img: '../../../assets/mechnical.svg', decsription: 'We specialize in civil construction services such as Rehabilitation works, Pipeline laying and connection, Structure painting and finishing works.' }, { title: 'Mechanical', img: '../../../assets/Technology.svg', decsription: 'We solve complex engineering problems backed by more then 30 years of experience in fabrication , manufacturing and consulting engineering.' }, { title: 'Technology', img: '../../../assets/mechnical.svg', decsription: 'Our brand rheo develops ai, chatbot and live chat software for better sales growth, customer engagement and customer service' }]
  constructor() { }

  ngOnInit() {
  }

}
