import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './components/footer/footer.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { QualificationCertificateComponent } from './pages/qualification-certificate/qualification-certificate.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { HomeComponent } from './pages/home/home.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CaseStudySmallComponent } from './components/case-study-small/case-study-small.component';
import { ServiceCardsComponent } from './components/service-cards/service-cards.component';
import { ClientLogosComponent } from './components/client-logos/client-logos.component';
import { CarouselSmallComponent } from './components/carousel-small/carousel-small.component';
import { CivilComponent } from './pages/civil/civil.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { CaseStudiesComponent } from './pages/case-studies/case-studies.component';
import { BannerComponent } from './components/banner/banner.component';
import { ProductsComponent } from './components/products/products.component';
import { PipingSolutionsComponent } from './pages/piping-solutions/piping-solutions.component';
import { SearchComponent } from './components/search/search.component';
import { FilterAccordianComponent } from './components/filter-accordian/filter-accordian.component';
import { ProductItemCardComponent } from './components/product-item-card/product-item-card.component';
import { ViewProductComponent } from './pages/view-product/view-product.component';
import { FabricationComponent } from './pages/fabrication/fabrication.component';
import { BallComponent } from './pages/piping-solutions/ball/ball.component';
import { CompressionComponent } from './pages/piping-solutions/compression/compression.component';
import { SaddleComponent } from './pages/piping-solutions/saddle/saddle.component';
import { ReducingCouplingComponent } from './pages/piping-solutions/reducing-coupling/reducing-coupling.component';
import { ElbowFemaleComponent } from './pages/piping-solutions/elbow-female/elbow-female.component';
import { ElbowMaleComponent } from './pages/piping-solutions/elbow-male/elbow-male.component';
import { MaleThreadedComponent } from './pages/piping-solutions/male-threaded/male-threaded.component';
import { FemaleThreadedComponent } from './pages/piping-solutions/female-threaded/female-threaded.component';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 
import { ToastrModule } from 'ngx-toastr';
import { PolicieComponent } from './pages/policie/policie.component';
import { OwlModule } from 'ngx-owl-carousel';
import { OwlCarouselComponent } from './components/owl-carousel/owl-carousel.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AboutUsComponent,
    QualificationCertificateComponent,
    ContactUsComponent,
    HomeComponent,
    CarouselComponent,
    CarouselSmallComponent,
    CaseStudySmallComponent,
    ServiceCardsComponent,
    ClientLogosComponent,
    CarouselSmallComponent,
    CivilComponent,
    TabsComponent,
    CaseStudiesComponent,
    BannerComponent,
    ProductsComponent,
    PipingSolutionsComponent,
    SearchComponent,
    FilterAccordianComponent,
    ProductItemCardComponent,
    ViewProductComponent,
    FabricationComponent,
    BallComponent,
    CompressionComponent,
    SaddleComponent,
    ReducingCouplingComponent,
    ElbowFemaleComponent,
    ElbowMaleComponent,
    MaleThreadedComponent,
    FemaleThreadedComponent,
    PolicieComponent,
    OwlCarouselComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    OwlModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
