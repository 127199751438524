import { Component, OnInit } from '@angular/core';
import {constant} from '../../constant/constant'
@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss']
})
export class CaseStudiesComponent implements OnInit {
  caseStudyContents = constant.caseStudyContent;
  constructor() { }

  ngOnInit() {
  }

}
