import { Component, OnInit } from '@angular/core';
import { faChevronDown,faBars,faTimes } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public icons = {
    dropDown: faChevronDown,
    hamberger:faBars,
    close:faTimes
  }
  toggle =false
  constructor() { }

  ngOnInit() {
  }

  openMenu() {
 this.toggle = !this.toggle
  }
}
