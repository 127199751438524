import { Component, OnInit } from '@angular/core';
import { faChevronDown,faBars,faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public icons = {
    dropDown: faChevronDown,
    hamberger:faBars,
    close:faTimes
  }
  constructor() { }

  ngOnInit() {
  }

}
