import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbSlideEvent, NgbSlideEventSource, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-carousel-small',
  templateUrl: './carousel-small.component.html',
  styleUrls: ['./carousel-small.component.scss']
})
export class CarouselSmallComponent implements OnInit {
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  public stars = [1, 2, 3, 4];
  public unStars = [1];
  constructor() { }
  images = [1, 2, 3].map((data) => {
    // return {
    //   id: 'slideOne',
    //   img: `https://picsum.photos/900/500?random&t=${Math.random()}`,
    //   title: 'Lorem ipsum dolor sit amet.' + data,
    //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias aliquid quaerat qui magnam consequatur voluptatum earum magni molestias blanditiis neque porro, perferendis quam cupiditate consequuntur ' + data
    // }
  });
  ngOnInit() {
  }
  prev() {
    this.carousel.prev();
  }
  next() {
    this.carousel.next();
  }

}
