import { Component, OnInit } from '@angular/core';
import { NgbSlideEvent, NgbSlideEventSource, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-client-logos',
  templateUrl: './client-logos.component.html',
  styleUrls: ['./client-logos.component.scss']
})
export class ClientLogosComponent implements OnInit {
  clients = [1,2,3,4,5]
  constructor() { }

  ngOnInit() {
  }

  public clientsLog = [
    {
      img:`/assets/client/RIL_Logo.png`
    },
    {
      img:'/assets/client/logo_fabtech.png'
    },
    {
      img:'/assets/client/IOT INFRA.jpg'
    },
    {
      img:'/assets/client/oritech.jpg'
    },
    {
      img:'/assets/client/logo.png'
    },
    {
      img:'/assets/client/MCCPL.jpg'
    },
    {
      img:'/assets/client/logo-lalitpur-bajaj.gif'
    },
    {
      img:'/assets/client/ISO-9001-CERTIFIED-249x300.png'
    }
  ]

}
